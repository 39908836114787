import React from 'react';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { Manage } from 'cccisd-laravel-appdefs';

const Appdefs = window.cccisd.appDefs;

const UberadminManage = props => {
    const { isSamlOn } = props;

    // Must clone deep so we can modify while preserving original props in memory
    const modifiedProps = _cloneDeep(props);

    try {
        const originalFields = Appdefs.routes
            .find(r => r.handle === 'uberadmin_manage')
            .componentProps.verticalNav.find(nav => nav.handle === 'groupingUnit').contentConfig.form.fields;

        if (!isSamlOn) {
            modifiedProps.verticalNav.find(nav => nav.handle === 'groupingUnit').contentConfig.form.fields =
                props.verticalNav
                    .find(nav => nav.handle === 'groupingUnit')
                    .contentConfig.form.fields.filter(
                        field => !['samlEntityId', 'samlLoginUrl', 'samlLogoutUrl', 'samlCert'].includes(field.handle)
                    );
        } else {
            modifiedProps.verticalNav.find(nav => nav.handle === 'groupingUnit').contentConfig.form.fields =
                originalFields;
        }
    } catch (e) {
        // meh no big deal
        console.error('Could not modify Manage props for Uberadmin. Rendering with basic props.');
    }

    return <Manage {...modifiedProps} />;
};

const mapStateToProps = state => {
    return { isSamlOn: !!_get(state, 'form.DynamicForm.settings-saml-enabled.value', false) };
};

export default connect(mapStateToProps, {})(UberadminManage);
